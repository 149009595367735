import React from 'react';
import './SponsorsLogos.scss';
import mlhLogo from './logos/mlh-logo-color.webp';
import upsurgeLogo from './logos/upsurge.webp';
import logotechLogo from './logos/logitech.webp';
import tezosLogo from './logos/tezos.webp';
import wolframLogo from './logos/wolfram-black.webp';
import jetbrainsLogo from './logos/jetbrains.webp';
import axureLogo from './logos/axure.webp';
import reskillLogo from './logos/reskilll-logo.webp';
import azureDevLogo from './logos/azdev-logo.webp';
import mongo from './logos/mongodb.webp';
import godday from './logos/godaddy-registry.webp';
import auth0 from './logos/auth0.webp'
import streamlit from './logos/streamlit.webp'
import ttinymce from './logos/tinymce.webp'

const sponsorsData = {
  leaguePartner: [{ name: 'MLH', logo: mlhLogo }],
  diamond: [{ name: 'Upsurge', logo: upsurgeLogo }],
  gold: [{ name: 'Logotech', logo: logotechLogo }],
  blockchainPartner: [{ name: 'Tezos', logo: tezosLogo }],
  generalSponsors: [
    { name: 'Wolfram', logo: wolframLogo },
    { name: 'Jetbrains', logo: jetbrainsLogo },
    { name: 'Axure', logo: axureLogo },
    { name: 'MongoDB', logo: mongo },
    { name: 'Godaddy', logo: godday },
    { name: 'Godaddy', logo: auth0 },
    { name: 'Godaddy', logo: streamlit },
    { name: 'Godaddy', logo: ttinymce },
  ],
  communityPartners: [
    { name: 'Reskill', logo: reskillLogo },
    { name: 'Azure Developer Community', logo: azureDevLogo },
  ],
};

const LeaguePartner = () => (
  <div className="htm-sponsors-sponsor-category league-partner">
    <h2 className="htm-sponsors-title htm-sponsors-league-partner-title">League Partner</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-league-partner-logos">
      {sponsorsData.leaguePartner.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const DiamondSponsors = () => (
  <div className="htm-sponsors-sponsor-category diamond">
    <h2 className="htm-sponsors-title htm-sponsors-diamond-title">Diamond Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-diamond-logos">
      {sponsorsData.diamond.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const GoldSponsors = () => (
  <div className="htm-sponsors-sponsor-category gold">
    <h2 className="htm-sponsors-title htm-sponsors-gold-title">Gold Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-gold-logos">
      {sponsorsData.gold.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const BlockchainPartners = () => (
  <div className="htm-sponsors-sponsor-category blockchain-partner">
    <h2 className="htm-sponsors-title htm-sponsors-blockchain-partner-title">Blockchain Partner</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-blockchain-partner-logos">
      {sponsorsData.blockchainPartner.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const GeneralSponsors = () => (
  <div className="htm-sponsors-sponsor-category general-sponsors">
    <h2 className="htm-sponsors-title htm-sponsors-general-sponsors-title">General Sponsors</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-general-sponsors-logos">
      {sponsorsData.generalSponsors.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const CommunityPartners = () => (
  <div className="htm-sponsors-sponsor-category community-partners">
    <h2 className="htm-sponsors-title htm-sponsors-community-partners-title">Community Partners</h2>
    <div className="htm-sponsors-sponsor-logos htm-sponsors-community-partners-logos">
      {sponsorsData.communityPartners.map((sponsor, index) => (
        <div key={index} className="htm-sponsors-sponsor-logo">
          <img src={sponsor.logo} alt={sponsor.name} />
        </div>
      ))}
    </div>
  </div>
);

const SponsorPage = () => {
  return (
    <div className="htm-sponsors-sponsors-container">
      <div className="htm-sponsors-sponsors-wrapper">
        <DiamondSponsors />
        <GoldSponsors />
        <BlockchainPartners />
        <GeneralSponsors />
        <LeaguePartner />
        <CommunityPartners />
      </div>
    </div>
  );
};

export default SponsorPage;
