import React, { useState,useEffect } from 'react';
import './PrizeChallenge.scss';
import trophy from './trophy.svg'
// import image from './image.svg'
import TruncatedText from './TruncatedText';
import mongo from './mongodb.webp'
import mongomobile from './mongomobile.png'
import godaddy from './godaddy-registry.webp'
import auth0 from './auth0.webp'
import streamlit from  './streamlit.webp'
import tinymce from './tinymce.webp'

const PrizeChallenges = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const challenges = [
    {
      name: 'Best Use of MongoDB Atlas',
      description: 'Build your hack using MongoDB Atlas to host a database in the cloud! ',
      prize: 'M5GO IoT Starter Kit',
      imageUrl:windowWidth <= 768 ? mongomobile : mongo,
      challenge_details:"MongoDB Atlas takes the leading modern database and makes it accessible in the cloud! Get started with a $50 credit for students or sign up for the Atlas free forever tier (no credit card required). Along with a suite of services and functionalities, you'll have everything you need to manage all of your data, and you can get a headstart with free resources from MongoDB University! Build a hack using MongoDB Atlas for a chance to win a M5GO IoT Starter Kit for you and each member of your team."
    },
    {
      name: 'Best Domain Name from GoDaddy Registry',
      description: 'GoDaddy Registry is giving you a free domain name of your choice! ',
      prize: 'Hack from Home Kit',
      challenge_details:"GoDaddy Registry is giving you everything you need to be the best hacker no matter where you are. Register your domain name with GoDaddy Registry for a chance to win a Hack from Home Kit! Each Kit contains wireless earbuds, blue light glasses, selfie ring light and a pouch for easy transport.",
      imageUrl: godaddy
     },
    {
      name: 'Best Use of Auth0',
      description: 'Use Auth0 in your hack for seamless authentication',
      prize: 'Wireless Headphones & Battery Pack',
      imageUrl: auth0,
      challenge_details:"Auth0 wants your applications to be secure! Why spend hours building features like social sign-in, Multi-Factor Authentication, and passwordless log-in when you can enable them through Auth0 straight out of the box? Auth0 is free to try, doesn’t require a credit card, and allows for up to 7,000 free active users and unlimited log-ins. Make your new account today and use any of the Auth0 APIs for a chance to win a pair of wireless headphones and a battery pack for you and each member of your team!"
    },{
      name: 'Best Use of Streamlit',
      description: 'Use Streamlit in your hack by creating an app/webapp for machine learning, data science, and more',
      prize: 'Arduino Tiny ML Kit',
      imageUrl: streamlit,
      challenge_details:"Want to take your Python skills to the next level? Streamlit is an open-source Python library that makes it easy to share and build custom web apps for machine learning, data science, and more. Instead of writing Python scripts without a UI, you can create a beautiful web app, complete with widgets and data visualizations, and deploy it for free to the Streamlit Community Cloud. You can even build a fully functional LLM-powered app with just 25 lines of code! Hack with Streamlit this weekend for your chance to win an Arduino Tiny ML Kit for you and each member of your team. "
    },
    {
      name: 'Best Use of TinyMCE',
      description: 'Use TinyMCE in your hack to enable a rich text editor',
      prize: '3D Printing Pen',
      imageUrl: tinymce,
      challenge_details:"TinyMCE is a rich-text editor that allows you to create formatted content within a hacker-friendly interface. Adding a Rich Text Editor component has never been easier! Alongside functionality like bold, italics, underlines, hyperlinks, or titles (to name a few), the editor also allows users to enable HTML tags, MarkDown or CSS. TinyMCE even gives you the ability to edit your text elements directly with no code, similar to the way you would edit a word document but with even more practical hackathon use cases. Signup for TinyMCE for a free trial and get started today, no credit card required! To qualify for the Best Use of TinyMCE prize category, be sure to render the editor with a Tiny Cloud account for a chance to win a 3D Printing Pen for you and each of your team members!"
    },

    // Add more challenges as needed
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showDetails, setShowDetails] = useState([]);
  
  const toggleDetails = (index) => {
    const updatedShowDetails = [...showDetails];
    updatedShowDetails[index] = !updatedShowDetails[index];
    setShowDetails(updatedShowDetails);
  };

  return (
    <section className="sponsors-challenges-section">
      <div className="sponsors-challenges-title-container">
        <h2 className="sponsors-challenges-section-title">LET'S HACK</h2>
        <h1 className="sponsors-challenges-title">SPONSOR'S CHALLENGES</h1>
      </div>
      <div className="sponsors-challenges-cards">
        {challenges.map((challenge, index) => (
          <div
            className="sponsor-challenge-card"
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="challenge-content">
              <div className="challenge-info">
                <div className="challenge-name-container">
                  <h2 className="challenge-name">
                    <TruncatedText
                      text={challenge.name}
                      desktopMaxLength={20}
                      mobileMaxLength={10}
                      isHovered={hoveredIndex === index}
                    />
                  </h2>                                                                                                
                </div>
                <div className="challenge-description-container">
                  <p className="challenge-description">
                    <TruncatedText
                      text={challenge.description}
                      desktopMaxLength={50}
                      mobileMaxLength={20}
                      isHovered={hoveredIndex === index}
                    />
                  </p>
                </div>
                <p className="prize-info">
                  <img src={trophy} className="trophy-icon" alt="Trophy Icon" />
                  <span className="prize-amount"><TruncatedText
                      text={challenge.prize}
                      desktopMaxLength={18}
                      mobileMaxLength={10}
                      isHovered={hoveredIndex === index}
                    /></span>
                  <span className="winner-text">For Winner</span>
                </p>
                <button className="sponsors-view-more-btn" onClick={() => toggleDetails(index)}>
                  {showDetails[index] ? 'VIEW LESS' : 'VIEW MORE'}
                </button>
              </div>
              <div className="challenge-image-container">
              <img
                loading="lazy"
                src={challenge.imageUrl}
                className="challenge-image"
                alt={`${challenge.name} visual representation`}
              />
              </div>
            </div>
            <div className={`challenge-details ${showDetails[index] ? 'show' : ''}`}>
              <p>{challenge.challenge_details}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PrizeChallenges;