import React from 'react';
import './Meetup.scss';
import Navbar from '../../components/Navbar/Navbar';
import MeetupPlace from '../../components/MeetupPlaces/MeetupPlaces';
import Footer from '../../components/Footer/Footer';
import img from './background.svg'; // Add your meetup background image here

const Meetup = () => {
  return (
    <>
      <div className="meetup-container">
        <Navbar />
        <section className="meetup-section">
          <img
            src={img}
            alt="Meetup background"
            className="meetup-background"
          />
          <div className="meetup-content">
            <h1 className="meetup-title">IN PERSON MEETUPS</h1>
            <p className="meetup-subtitle">CREATE | COLLABORATE | COMMUNICATE</p>
          </div>
          <div className="whitish-overlay"></div>
        </section>
        <div className="gradient-overlay"></div>
      </div>
      <MeetupPlace/>
      <Footer/>
    </>
  );
};

export default Meetup;
