import React from 'react'
import "./App.scss"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import Team from './pages/Team/Team';
import Speaker from './pages/Speaker/Speaker';
import Swag from './pages/Swag/Swag'
import Meetup from './pages/Meetup/Meetup';
import Prizes from './pages/Prizes/Prizes';
import SponsorPage from './pages/SponsorPage/SponsorPage';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/workshops" element={<Speaker/>}/>
          <Route path="/swags" element={<Swag/>}/>
          <Route path='/meetup' element={<Meetup/>}/>
          <Route path='/prizes' element={<Prizes/>}/>
          <Route path='/sponsors' element={<SponsorPage/>}/> 
          </Routes>
        </Router>
    </div>
  )
}

export default App
